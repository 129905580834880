<template>
  <div>
    <v-row v-if="isSales" no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="customerId"
          :items="getSearchCustomers"
          :loading="searchingCustomerFilter"
          :search-input.sync="searchCustomerWatchFilter"
          item-text="Name"
          item-value="Id"
          clearable
          hide-no-data
          :placeholder="$t('Customer')"
          @change="getInvoicesAndCreditNotes()"
          return-object
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-card v-show="showCard">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("Document") }}</th>
                <th class="text-left">{{ $t("Description") }}</th>
                <th class="text-left">{{ $t("Type") }}</th>
                <th class="text-left">{{ $t("Date") }}</th>
                <th class="text-left">{{ $t("Posting date") }}</th>
                <th class="text-left">{{ $t("Due Date") }}</th>
                <th class="text-left">{{ $t("Amount") }}</th>
                <th class="text-left">{{ $t("Remaining") }}</th>
                <th class="text-left">
                  {{ $t("Paid") }}
                </th>
                <th class="text-right">
                  <v-layout justify-end align-center>
                    {{ $t("File") }}
                  </v-layout>
                </th>
              </tr>
            </thead>
            <tbody v-if="!isLoadingDocuments && invoicesAndCreditNotes.length">
              <tr
                v-for="(document, index) in invoicesAndCreditNotes"
                :key="index"
              >
                <td>
                  {{ document.DocumentNo }}
                </td>
                <td>
                  {{ document.Description }}
                </td>
                <td>
                  {{ $t(document.DocumentType) }}
                </td>
                <td>
                  {{ changeToDMY(document.DocumentDate) }}
                </td>
                <td>
                  {{ changeToDMY(document.PostingDate) }}
                </td>
                <td>
                  {{ changeToDMY(document.DueDate) }}
                </td>
                <td>
                  {{
                    currencyCodeToSign(document.CurrencyCode) +
                    " " +
                    document.Amount
                  }}
                </td>
                <td>
                  {{
                    currencyCodeToSign(document.CurrencyCode) +
                    " " +
                    document.RemainingAmount
                  }}
                </td>
                <td>
                  <v-layout>
                    <v-icon v-if="!document.Open" color="success">
                      mdi-check
                    </v-icon>
                    <v-icon v-else color="error"> mdi-close </v-icon>
                  </v-layout>
                </td>

                <td class="d-flex justify-end align-center">
                  <v-btn
                    :loading="isDownloading[index]"
                    :disabled="!document.DocumentNo"
                    fab
                    x-small
                    @click="downloadDocument(document, index)"
                  >
                    <v-icon color="primary">mdi-file-download-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="invoicesAndCreditNotes.length == 0">
              <tr>
                <td colspan="10">
                  <v-layout
                    justify-center
                    align-center
                    fill-height
                    class="my-4"
                  >
                    {{ $t("No data available") }}
                  </v-layout>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10">
                  <preloader />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
    
<script>
import { mapGetters } from "vuex";
import helpers from "../../helpers";
import request from "../../request";
import Preloader from "./Preloader.vue";

export default {
  components: { Preloader },
  props: {
    isSales: {
      type: Boolean,
    },
  },
  data() {
    return {
      invoicesAndCreditNotes: [],
      isDownloading: [],
      searchCustomers: [],
      searchCustomerWatchFilter: "",
      searchingCustomerFilter: false,
      customerId: null,
      isLoadingDocuments: false,
      timerSearchCustomer: null,
    };
  },
  created() {
    if (!this.isSales) {
      this.getInvoicesAndCreditNotes();
    }
  },
  computed: {
    ...mapGetters({
      websites: "allWebsites",
    }),
    getSearchCustomers() {
      if (!this.searchCustomers) {
        return [];
      }
      this.searchCustomers.forEach((element) => {
        element.Name = element.Name + " (" + element.Code + ")";
      });
      return this.searchCustomers;
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    showCard() {
      if (this.isSales && !this.customerId) {
        return false;
      }
      return true;
    },
  },
  watch: {
    searchCustomerWatchFilter: function (searchValue) {
      if (searchValue == null) {
        return;
      }
      clearTimeout(this.timerSearchCustomer);
      this.timerSearchCustomer = setTimeout(() => {
        if (searchValue != "") {
          this.searchCustomerFilter(searchValue);
        }
      }, 200);
    },
  },
  methods: {
    getInvoicesAndCreditNotes() {
      this.isLoadingDocuments = true;
      const id = this.customerId ? "?customerId=" + this.customerId.Id : "";
      const url = "/api/kdesign/b2b-customer-documents" + id;
      request.get(url, null, (res) => {
        this.isLoadingDocuments = false;

        if (res.success) {
          this.invoicesAndCreditNotes = res;
        }
      });
    },
    searchCustomerFilter(search) {
      if (this.searchingCustomerFilter) return;
      this.searchingCustomerFilter = true;
      request.get("/api/kdesign/customers?search=" + search + "&getAll=true", null, (res) => {
        this.searchCustomers = res;
        this.searchingCustomerFilter = false;
      });
    },
    currencyCodeToSign(item) {
      let currencySign;
      switch (item) {
        case "EUR":
          currencySign = "€ ";
          break;
        case "GBP":
          currencySign = "£ ";
          break;
        case "USD":
          currencySign = "$ ";
          break;

        default:
          currencySign = "€ ";
          break;
      }
      return currencySign;
    },
    downloadDocument(doc, index) {
      this.isDownloading[index] = true;
      const file = doc.DocumentNo;
      const url = this.isSales
        ? "/api/kdesign/b2b-customer-document-pdf?documentGuid=" +
          doc.BCGuid +
          "&customerId=" +
          this.customerId.Id
        : "/api/kdesign/b2b-customer-document-pdf?documentGuid=" + doc.BCGuid;

      request.download(url, { responseType: "blob" }, file + ".pdf", () => {
        this.isDownloading[index] = false;
      });
    },
    changeToDMY(item) {
      return helpers.changeTimeToReadebleDMY(item);
    },
  },
};
</script>
    
    <style scoped>
.height-card {
  height: 75vh;
}
</style>